import './App.css';
import { First } from './components/first/';
import { useEffect } from 'react';
import { Second } from './components/second';
import { gsap } from "gsap";
import 'react-slideshow-image/dist/styles.css'
    
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { TextPlugin } from "gsap/TextPlugin";
import { EasePack } from "gsap/EasePack";

/* The following plugins are Club GSAP perks */
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import { ScrambleTextPlugin } from "gsap/ScrambleTextPlugin";
import { SplitText } from "gsap/SplitText";
import { Third } from './components/third';
import { Fourth } from './components/fourth';


gsap.registerPlugin(ScrollTrigger,ScrollToPlugin,TextPlugin,DrawSVGPlugin,ScrollSmoother,ScrambleTextPlugin,SplitText, EasePack);
// @ts-ignore
export var smoother;


function App() {
  useEffect(() => {
    smoother = ScrollSmoother.create({
      smooth: 1,
      effects: true,
      ignoreMobileResize: true,
      smoothTouch: 0.1
    });
  let cursor = document.querySelector('.cursor');
  // @ts-ignore
  let cursorScale = [...document.querySelectorAll('#cursor-scale'), ...document.querySelectorAll('.cursor-scale')]; 
  let mouseX = 0;
  let mouseY = 0;

  gsap.to({}, 0.016, {
    repeat: -1,
    onRepeat: function(){
      gsap.set(cursor, {
        css: {
          left: mouseX,
          top: mouseY,
        }
      })
    }
  });

  window.addEventListener('mousemove', (e)=> {
    mouseX = e.clientX;
    mouseY = e.clientY;
  })

  cursorScale.forEach(link => {
    link.addEventListener('mousemove', ()=> {
      cursor?.classList.add('grow'); 
      if (link.classList.contains('small')){
        cursor?.classList.remove('grow');
        cursor?.classList.add('grow-small');
      }
    });
    
    link.addEventListener('mouseleave', ()=> {
      cursor?.classList.remove('grow');
      cursor?.classList.remove('grow-small');
    });
  })
    }, [])
  return (
    <div id="smooth-wrapper" className="App">
      <div className="cursor"></div>
      <div id="smooth-content">
      <First/>
      <Second/>
      <Third/>
      <Fourth/>
      </div>
    </div>
  );
}

export default App;
