import classes from './fourth.module.css';
import { useForm } from "react-hook-form";
import { Slides } from './slides';
import {gsap} from 'gsap';
export const Fourth = () => {
  const { register, handleSubmit, watch, formState: { errors } } = useForm<{name: string, phone:string, descr: string}>();
  const onSubmit = ( data: any ) => {
    fetch('https://eu-api.jotform.com/form/233343274487057/submissions?apiKey=0c2a198b2ff45546401ba3e970cc0468', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: `submission[4]=${data.name}&submission[3]=${data.phone}&submission[5]=${data.descr}`
    });
    const tl = gsap.timeline();
    tl.to('#scramblesubmit', {
      duration: 2, 
      tweenLength: false,
      scrambleText:{  text: "Дякуємо!", chars: "АаБбВвГгҐґДдЕеЄєЖжЗзИиІіЇїЙйКкЛлМмНнОоПпРрСсТтУуФфХхЦцЧчШшЩщьЮюЯя"}
    });
    tl.to('#scramblesubmit', {
      duration: 2, 
      delay: 1,
      tweenLength: false,
      scrambleText:{  text: "Ми з вами звʼяжемося", chars: "АаБбВвГгҐґДдЕеЄєЖжЗзИиІіЇїЙйКкЛлМмНнОоПпРрСсТтУуФфХхЦцЧчШшЩщьЮюЯя"}
    });
    tl.to('#submitbutton', {
      duration: 4, 
      delay: 1,
      opacity: 0,
      ease: 'power4.out',
    });
    };
  return <>
  <Slides/>
  <div  className={classes.container}>
    <div id="cursor-scale" className={classes.firstContainer}>
      <span>Почнемо?</span>
      <span>Ми надаємо індивідуальний підхід до кожного проєкту
Ми формуємо команду, яка спеціально призначена для
конкретного завдання і приділяє проєкту достатньо
часу для розробки стратегії найвищого класу.
Наша мета - досягнення результату, якого очікує клієнт,
і ми вкладаємо всю необхідну енергію та експертизу в
створення успішних стратегій для кожного проєкту.</span>
    </div>
      <div id="cursor-scale" className={classes.secondContainer}>
        <input className={classes.input} placeholder='Ваше ім`я' {...register("name", { required: true })} />
        {errors.name && <span style={{color: 'red'}}>Обовʼязково до заповнення</span>}
        <input autoComplete='mobile tel' type="tel" className={classes.input} placeholder='+38 (000) 000 00 00 або ваш Telegram' {...register("phone", { required: true })} />
        {errors.phone && <span style={{color: 'red'}}>Обовʼязково до заповнення</span>}
        <input className={classes.input} placeholder='Коротко про вашу задачу' {...register("descr")} />
        
        <button id="submitbutton" className={classes.inputSubmit} onClick={handleSubmit(onSubmit)} >
          <span id="scramblesubmit">Відправити</span>
          <div className={classes.arrow}/>
        </button>
    </div>
  </div>
  <div className={classes.watermark}>
    <a target="_blank" href="https://thebeta.studio" rel="noreferrer">
      <span>made by thebeta.studio</span>
    </a>
  </div> 
  </>
  }
