import classes from './header.module.css';
import {ReactComponent as MainLogo} from './assets/mainlogo.svg';
import { FirstContent } from './content';
import { smoother } from '../../../App';
export const Header = () => {

  return <div  className={classes.container}>
  <MainLogo data-speed="clamp(2)"className={classes.mainLogo}/>
  <div data-speed="clamp(2)"className={classes.headerContainer}>
    <span onClick={() => smoother.scrollTo("#secondnav", true, "top 100px")}id="cursor-scale">ПРО T-WORK</span>
    <span onClick={() => smoother.scrollTo("#fourthnav", true, "top 0")}id="cursor-scale">КЕЙСИ</span>
    <span onClick={() => smoother.scrollTo("#thirdnav", true, "top 0")}id="cursor-scale">ПОСЛУГИ</span>
    <span onClick={() => smoother.scrollTo("#thirdnav", true, "top 100px")}id="cursor-scale">КОНСАЛТИНГ</span>
    </div>
  <FirstContent/>
  </div>
}
