import classes from './content.module.css';
import {ReactComponent as Alogo} from '../assets/alogo.svg';
export const FirstContent = () => {
  return <div id="cursor-scale" className={classes.container}>
  <div>
    <Alogo/>
    <span id="logoBackground" className={classes.svgFill}/>
  </div>
  
  <span data-speed="clamp(2)"> Агенція інтернет-маркетингу</span>
  <span data-speed="clamp(2)" id="animateType"></span>
  <div className={classes.animateType2} data-speed="clamp(2)" id="animateType2"></div>
  </div>
  }
