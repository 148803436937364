import gsap from 'gsap';
import { useEffect, useLayoutEffect } from 'react';
import classes from './cards.module.css';
//@ts-ignore
import Popup from 'react-popup';
import { Slide } from 'react-slideshow-image';
export const Cards3 = () => {
  useLayoutEffect(() => {
    const tl = gsap.timeline({scrollTrigger: {
        trigger: "#firstContainer", // make .panel2 the trigger
        start: "110% bottom", // 10% of .panel2 enters the bottom of the viewport
        once: true,
      }});
      tl.from("#firstContainer", {x: -800, duration: 2, ease: 'back.out'})
      tl.from("#secondContainer", {x: 800, duration: 2, ease: 'back.out'}, '>-1.5')
      tl.from("#thirdContainer", {x: -800, duration: 2, ease: 'back.out'}, '>-1.5')
      tl.from("#fourthContainer", {y: 500, duration: 2, ease: 'back.out'}, '>-1.5')
      tl.from("#fifthContainer", {x: 500, duration: 2, ease: 'back.out'}, '>-1.5')
      // setTimeout(() => tl.reverse(), 5000)
      // tl.reverse();
    }, [])
  return <div id="cursor-scale" data-lag="0.5" className={classes.container}>
    <div onClick={() => Popup.alert(<div id="cursor-scale">
      <div className={classes.popupContainer}>
        <span>01</span>
        <span>SMM-просування</span>
      </div>
          <Slide slidesToScroll={1} slidesToShow={1} indicators={true} arrows={false} >
            <div className="each-slide-effect-pop">
                <div className={classes.popupContainerRoot}>
                  <li>Визначення цілей</li>
                  <span> Встановлюємо чіткі цілі для досягнення результату в соціальних мережах, прагнучи перевершити ваші очікування.
                  </span>
                  <li>Аналіз активності в соцмережах</li>
                  <span>Ми глибоко вивчаємо ефективність різних видів контенту, виділяючи ті, що працюють найкраще, і визначаємо області для поліпшення. Це надає нам можливість виявити потенціал для подальшого зростання.
                  </span>
                  <li>Аналіз аудиторії та конкурентів</li>
                  <span>Ми ретельно вивчаємо вашу цільову аудиторію та аналізуємо стратегії конкурентів. Наша мета - зрозуміти, що діє, і вивчити методи привертання уваги підписників.</span>
                </div>
            </div>
            <div className="each-slide-effect-pop">
                <div className={classes.popupContainerRoot}>
                  <li>Розробка стратегії та плану контенту</li>
                  <span>Ми створюємо ефективну стратегію для направлення зусиль в потрібному напрямку, а план контенту забезпечує послідовність та високу якість публікацій.</span>
                  <li>Поліпшення зовнішнього вигляду профілю</li>
                  <span>Ми оновлюємо шапку профілю, закріплені історії, створюємо захоплюючі пости, історії та Reels. Це сприяє залученню необхідної реакції від вашої цільової аудиторії.</span>
                  <li>Моніторинг та коригування</li>
                  <span>Ми уважно стежимо за реакціями користувачів і отримуємо зворотній зв'язок, вчасно вносячи необхідні зміни в стратегію для максимізації результатів.</span>
                </div>
            </div>
            </Slide>
    </div>)} id="firstContainer" className={classes.cardContainer1} style={{cursor: 'pointer'}}>
      <div className={classes.cardContainer1d1}>
        <span>01</span>
        <span>SMM-просування</span>
      </div>
      <ul className={classes.listContainer}>
        <li>Визначення цілей</li>
        <li>Аналіз активності в соцмережах</li>
        <li>Аналіз аудиторії та конкурентів</li>
        <li>Розробка стратегії та плану контенту</li>
        <li>Поліпшення зовнішнього вигляду профілю</li>
        <li>Моніторинг та коригування</li>
      </ul>
    </div>
    <div id="secondContainer" className={classes.cardContainer1}>
      <div className={classes.cardContainer1d1}>
        <span style={{background: 'black'}}>02</span>
        <span>Дизайн стрічки Instagram</span>
      </div>
      <ul className={classes.listContainer}>
        <li>Розробка концепту стрічки у єдиному стилі</li>
        <li>Підбір фото та створення мудборду</li>
        <li>Розробка концепту на основі брендбука</li>
        <li>Підбір шрифтів</li>
        <li>Оформлення Highlights під дизайн стрічки</li>
        <li>Ретуш фото</li>
      </ul>
    </div>
    <div id="thirdContainer" style={{background: 'red', color: 'white'}} className={classes.cardContainer1}>
      <div style={{background: 'red', color: 'white'}} className={classes.cardContainer1d1}>
        <span style={{background: 'black'}}>03</span>
        <span style={{background: 'red', color: 'white'}}>Дизайн рекламних банерів</span>
      </div>
      <ul style={{background: 'red', color: 'white'}}className={classes.listContainer}>
        <li style={{background: 'red', color: 'white'}}>Розробка реклами з урахуванням усіх вимог політики Facebook</li>
        <li style={{background: 'red', color: 'white'}}>Пошук та ретуш фото</li>
        <li style={{background: 'red', color: 'white'}}>Створення кольорової гами відповідно до айдентики бренду</li>
        <li style={{background: 'red', color: 'white'}}>Підбір шрифтів та розміщення тригерів, які продають</li>
        <li style={{background: 'red', color: 'white'}}>Гармонійна побудова тексту та візуальної складової</li>
      </ul>
    </div>
    <div onClick={() => Popup.alert(<div id="cursor-scale">
      <div className={classes.popupContainer}>
        <span>04</span>
        <span>Консалтинг</span>
        <span>Вам не доведеться проходити маркетингові курси та самостійно тестувати мільйон гіпотез. За фахівцями агенції T-Work стоїть 4 роки досвіду в галузі маркетингу, тож ми готові забезпечити вас готовими стратегіями, механізмами та пропозиціями для вашого бізнесу.</span>
      </div>
          <Slide slidesToScroll={1} slidesToShow={1} indicators={true} arrows={false} >
            <div className="each-slide-effect-pop">
                <div className={classes.popupContainerRoot2}>
                  <span>Як проходить консалтинг?</span>
                  <li>Збір та аналіз даних щодо діяльності компанії</li>
                  <li>Вивчення вашого бізнесу та галузі взагалі</li>
                  <li>Проведення брифінгу</li>
                  <li>Визначення цілей співпраці</li>
                  <li>Аналіз напрямків та існуючих пропозицій</li>
                  <li>Рекомендації щодо платформ, в яких можна спробувати розвивати бізнес</li>
                  <li>Підбір нових пропозицій, які гарантовано спрацюють</li>
                  <li>Підготовка рішень, які можна впровадити за допомогою соціальних мереж</li>
                  <li>Рекомендації щодо впровадження підготовлених рішень</li>
                  <li>Допомога у виборі візуального оформлення</li>
                  <li>Рекомендації щодо наповнення вашої сторінки в соціальних мережах</li>
                  <li>Аналіз результатів минулих дій</li>
                  <li>Визначення точок зростання та масштабування через соціальні мережі</li>
                  <li>Відстеження ефективності роботи</li>
                </div>
            </div>
            <div className="each-slide-effect-pop">
                <div className={classes.popupContainerRoot2}>
                  <span>Кому підійде?</span>
                    <li>У разі виходу товару чи послуги на новий ринок</li>
                    <li>У разі відсутності розвитку бізнесу</li>
                    <li>Якщо ви не використовували соціальні мережі у бізнесі</li>
                    <li>Якщо соціальні мережі не дають результату</li>
                    <li>Маркетологам, які хочуть розширити сферу знань</li>
                    <li>Власникам бізнесу, які бажають контролювати підрядників</li>
                    <li>Штатним SMM-спеціалістам, які потребують консультації</li>
                </div>
            </div>
            </Slide>
    </div>)} id="fourthContainer" style={{background: '#212121', color: 'white', cursor: 'pointer'}} className={classes.cardContainer1}>
      <div style={{background: '#212121', color: 'white'}} className={classes.cardContainer1d1}>
        <span style={{background: 'black'}}>04</span>
        <span style={{background: '#212121', color: 'white'}}>Консалтинг</span>
      </div>
      <ul style={{background: '#212121', color: 'white', justifyContent: 'end'}}className={classes.listContainer}>
        <li style={{background: '#212121', color: 'white'}}>Аудит</li>
        <li style={{background: '#212121', color: 'white'}}>Консульація</li>
        <li style={{background: '#212121', color: 'white'}}>Допомога</li>
      </ul>
    </div>
    <div id="fifthContainer" style={{background: '#212121', color: 'white'}} className={classes.cardContainer1}>
      <div style={{background: '#212121', color: 'white'}} className={classes.cardContainer1d1}>
        <span style={{background: 'black', alignSelf: 'flex-start'}}>05</span>
        <span style={{background: '#212121', color: 'white', }}>Motion Design</span>
      </div>
      <ul style={{background: '#212121', color: 'white', justifyContent: 'end'}}className={classes.listContainer}>
        <li style={{background: '#212121', color: 'white'}}>Сценарій та розкадровка</li>
        <li style={{background: '#212121', color: 'white'}}>Зйомка та ретуш</li>
        <li style={{background: '#212121', color: 'white'}}>Анімація тексту</li>
        <li style={{background: '#212121', color: 'white'}}>Анімація графічних елементів</li>
        <li style={{background: '#212121', color: 'white'}}>Корекція кольору відео </li>
      </ul>
    </div>
  </div>
  }
