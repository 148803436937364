import { Slide } from 'react-slideshow-image';
import classes from './slides.module.css';
  // @ts-ignore
import case1 from './assets/case1.webp';
  // @ts-ignore
import case2 from './assets/case2.webp';
  // @ts-ignore
import case3 from './assets/case3.webp';
  // @ts-ignore
import case4 from './assets/case4.webp';
import case5 from './assets/case5.webp';
import { mobileCheck } from '../first';

export const Slides = () => {

  return <div id="cursor-scale" className={classes.container}>
  <span id="fourthnav" >Кейси</span>
          <Slide slidesToScroll={mobileCheck()? 1: 1} slidesToShow={mobileCheck()? 1: 1} indicators={true} cssClass="slideWrapper">
            <div className="each-slide-effect">
                <img src={case1}/>
            </div>
            <div className="each-slide-effect">
                <img src={case2}/>
            </div>
            <div className="each-slide-effect">
                <img src={case3}/>
            </div>
            <div className="each-slide-effect">
                <img src={case4}/>
            </div>
            <div className="each-slide-effect">
                <img src={case5}/>
            </div>
        </Slide>
  </div>
  }
