import classes from './animate.module.css';
export const BottomAnimation = () => {
  return <div id="bottomAnimation"className={`${ classes.container } cursor-scale`}>
    <div id="move">Контент</div>
    <div id="move">Дизайн</div>
    <div id="move">Просування</div>
    <div id="move">Аудит</div>
    <div id="move">Стратегія</div>
    <div id="move">Контент</div>
    <div id="move">Дизайн</div>
    <div id="move">Просування</div>
    <div id="move">Аудит</div>
    <div id="move">Стратегія</div>
  </div>
  }
