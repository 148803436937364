import classes from './second.module.css'
import {ReactComponent as RedCircle} from './assets/redCircle.svg';
import {ReactComponent as SecondCircle} from './assets/second.svg';
import {ReactComponent as ThirdCircle} from './assets/third.svg';
import DBackground from './assets/dbackground.svg';
import Danila from './assets/danila.webp';
import DanilaBackround from './assets/danilaBackground.svg';
import gsap from 'gsap';
import { SplitText } from "gsap/SplitText";
import { useEffect } from 'react';
import { mobileCheck } from '../first';
export const Second = () => {
  useEffect(() => {
    const split = new SplitText("#average", {type: "words"}).words;
    const split2 = new SplitText("#average2", {type: "words"}).words;
    gsap.fromTo('#redSecond', {
      color: 'white',
    }, {
      duration: 2, 
      color: 'red',
      ease: "power4.out",
      scrollTrigger: {
              trigger: '#redSecond',
              start: "100% bottom", // 10% of .panel2 enters the bottom of the viewport
              once: true,
              },
    });
    // @ts-ignore
    gsap.fromTo('#result', {
      marginRight: mobileCheck() ? '85%': '50%',
      opacity: 0,
    }, {
      duration: 6, 
      marginRight: mobileCheck() ? '55%': '50%',
      opacity: 1,
      ease: "power4.out",
      scrollTrigger: {
              trigger: '#result',
              start: "100% bottom", // 10% of .panel2 enters the bottom of the viewport
              once: true,
              },
    }, ">-2");
    // @ts-ignore
    gsap.fromTo('#dosvid', {
      marginLeft: '50%',
      opacity: 0,
    }, {
      duration: 6, 
      marginLeft: '15%',
      opacity: 1,
      ease: "power4.out",
      scrollTrigger: {
              trigger: '#dosvid',
              start: "100% bottom", // 10% of .panel2 enters the bottom of the viewport
              once: true,
              },
    }, ">-5.5");
  // @ts-ignore
    gsap.fromTo('#ind', {
      marginLeft: '50%',
      opacity: 0,
    }, {
      duration: 6, 
      marginLeft: '55%',
      opacity: 1,
      ease: "power4.out",
      scrollTrigger: {
              trigger: '#ind',
              start: "100% bottom", // 10% of .panel2 enters the bottom of the viewport
              once: true,
              },
    }, ">-5.5");
    gsap.from(
    split,
    // @ts-ignore
    {
      y: '500%',
      opacity: 1,
      stagger: 0.01,
      duration: 2,
      ease: 'power4.out',
      scrollTrigger: {
              trigger: split,
              start: "100% bottom", // 10% of .panel2 enters the bottom of the viewport
              once: true,
              },
    }, ">-5")
    gsap.from(
    split2,
    // @ts-ignore
    {
      y: '1000%',
      opacity: 1,
      stagger: 0.01,
      duration: 2,
      ease: 'power4.out',
      scrollTrigger: {
              trigger: split2,
              start: "100% bottom", // 10% of .panel2 enters the bottom of the viewport
              once: true,
              },
    }, ">-2")
    const tlUvaga = gsap.timeline({        
      scrollTrigger: {
              trigger: "#scramble1", // make .panel2 the trigger
              start: "10% bottom", // 10% of .panel2 enters the bottom of the viewport
              once: true,
              },
    });
    tlUvaga.to('#scramble1', {
      duration: 2, 
      tweenLength: false,
      scrambleText:{  text: "увага!", chars: "АаБбВвГгҐґДдЕеЄєЖжЗзИиІіЇїЙйКкЛлМмНнОоПпРрСсТтУуФфХхЦцЧчШшЩщьЮюЯя"}
    });
    tlUvaga.fromTo('#scramble3', {
      duration: 0, 
      color: 'black',
    }, {
      duration: 2, 
      color: 'white',
    });
    tlUvaga.fromTo('#scramble3back', {
      duration: 0, 
      width: '0%',
      color: 'black',
    }, {
      duration: 2, 
      width: '100%',
      color: 'white',
    }, '-=2');
  }, [])
  return <div id="secondnav" className={classes.container}>
  <div className={classes.first} id="cursor-scale">
    <span>Чому</span>
    <span id="redSecond" >обирають</span>
  </div>
  <span className={classes.second} id="cursor-scale">нас?</span>
  <div data-lag="0.5" className={classes.third}>
    <span className="cursor-scale" id="average" >Середній термін співпраці клієнта з агентством T-Work складає 13 місяців</span>
  </div>
  <div className={`cursor-scale ${classes.fouth}`} id="result">
    <span>результат</span>
    <RedCircle></RedCircle>
  </div>
  <div className={`cursor-scale ${classes.fifth}`} id="dosvid">
    <span>досвід</span>
    <SecondCircle></SecondCircle>
  </div>
  <div className={ `cursor-scale ${classes.sixth}` } id="ind">
    <span>індивідуальний підхід</span>
    <ThirdCircle></ThirdCircle>
  </div>
  <span data-lag="0.5" id="average2" className={`${ classes.seventh } cursor-scale`}>Найперше, на що звертає увагу клієн при виборіагентства інтернет-маркетингу, - це багатий досвід та успішні кейси. Проте, лише досягнення цілей клієнта визначає подальший розвиток співпраці.</span>
  <span className={classes.eigth} id="cursor-scale">Наші послуги виділяються на тлі конкуренції завдяки досвіду провідного експерта -</span>
  <div className={classes.ninth} id="cursor-scale">
    <span>Данила</span>
    <span>Куранди</span>
    <img src={DBackground}/>
  </div>
  <div className={classes.tenth}>
    <img src={Danila}/>
    <img src={DanilaBackround}/>
    <span data-speed="1.1">Наші послуги виділяються на тлі конкуренції завдяки досвіду провідного експерта - Данила Куранди. Він має понад 5-річний досвід роботи СМО в одній з найбільших навчальних платформ - Соль. Це дозволяє йому глибоко розуміти особливості та виклики, з якими стикаються власники компаній.
</span>
    <span data-speed="1.1">СЕО успішної спільноти RISE(ех Магія Ранку), де активно взаємодіє з підприємцями, обговорює труднощі бізнесу та надає практичні рішення.
</span>
    <span data-speed="1.1">Знання всіх аспектів болей і
проблем власників бізнесу
дозволяє Данилу точно визначити
 причини, з яких підприємці відчувають
труднощі у привертанні клієнтів</span>
  </div>
  <div data-lag="0.1" className={classes.eleventh} id="cursor-scale">
   <span id="scramble1"></span> 
   <div>   
     <span id="scramble3">Ми надаємо індивідуальний підхід до кожного проєкту. </span> 
     <div id="scramble3back"/>
   </div>
   <span id="scramble4">Ми формуємо команду, яка спеціально призначена для конкретного 
завдання і приділяє проєкту достатньо часу для розробки стратегії 
найвищого класу. Наша мета - досягнення результату, якого очікує 
клієнт, і ми вкладаємо всю необхідну енергію та експертизу в 
створення успішних стратегій для кожного проєкту.</span> 
  </div>
  </div>
  }
